<template>
  <div v-if="!showDontHaveService">
    <v-container>
      <v-row align="center" justify="center">
        <v-card elevation="0" max-width="1100" width="100%" class="mb-5">
          <v-layout align-center justify-center>
            <v-flex xs12>
              <v-card class="px-3">
                <v-card-text class="pa-3">
                  <v-layout>
                    <v-flex xs12 :sm4="!isAdmin" :sm3="isAdmin">
                      <v-menu
                        ref="finiMenu"
                        v-model="finiMenu"
                        class="mr-2"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        :return-value.sync="fini"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            v-model="iniFormatted"
                            :label="$t('common.fecha_inicio') + ' *'"
                            append-icon="mdi-calendar"
                            readonly
                            class="input_fnacimiento"
                            v-on="on"
                            @blur="fini = parseDate(iniFormatted)"
                          />
                        </template>
                        <v-date-picker
                          ref="picker"
                          v-model="fini"
                          first-day-of-week="1"
                          :locale="this.$i18n.locale"
                          min="2017-01-01"
                          :max="fmax.toISOString().substr(0, 10)"
                          no-title
                          scrollable
                          @input="$refs.finiMenu.save(fini)"
                        />
                      </v-menu>
                    </v-flex>
                    <v-flex xs12 :sm4="!isAdmin" :sm3="isAdmin">
                      <v-menu
                        ref="ffinMenu"
                        v-model="ffinMenu"
                        class="mr-2"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        :return-value.sync="ffin"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            v-model="finFormatted"
                            :label="$t('common.fecha_fin') + ' *'"
                            append-icon="mdi-calendar"
                            readonly
                            class="input_fnacimiento"
                            v-on="on"
                            @blur="ffin = parseDate(finFormatted)"
                          />
                        </template>
                        <v-date-picker
                          ref="picker"
                          v-model="ffin"
                          first-day-of-week="1"
                          :locale="this.$i18n.locale"
                          min="2017-01-01"
                          :max="fmax.toISOString().substr(0, 10)"
                          no-title
                          scrollable
                          @input="$refs.ffinMenu.save(ffin)"
                        />
                      </v-menu>
                    </v-flex>

                    <v-flex v-if="isAdmin" xs12 sm4 class="mt-1">
                      <v-autocomplete
                        v-model="professional"
                        :label="$t('facturacion.select_professional')"
                        :items="professionals"
                        chips
                        item-text="nombre"
                        item-value="id"
                        item-valor="id"
                        dense
                      >
                        <template slot="selection" slot-scope="data">
                          {{ data.item.nombre + ' ' + data.item.apellidos }}
                        </template>
                        <template slot="item" slot-scope="data">
                          <template v-if="typeof data.item !== 'object'">
                            <v-list-tile-content v-text="data.item" />
                          </template>
                          <template v-else>
                            <v-list-tile-content>
                              <v-list-tile-title>{{ data.item.nombre + ' ' + data.item.apellidos }}</v-list-tile-title>
                            </v-list-tile-content>
                          </template>
                        </template>
                      </v-autocomplete>
                    </v-flex>
                    <v-flex xs12 :sm4="!isAdmin" :sm2="isAdmin" class="text-sm-right">
                      <v-btn
                        :disabled="disabledButton"
                        :color="custom_template ? 'primary' : 'info'"
                        @click="loadInvoices()"
                      >
                        <span><v-icon>mdi-magnify</v-icon>{{ $t('common.filtrar') }}</span>
                      </v-btn>
                    </v-flex>
                  </v-layout>
                </v-card-text>
              </v-card>
            </v-flex>
          </v-layout>
          <v-layout>
            <v-flex xs12>
              <v-card>
                <v-card-text>
                  <div v-if="loading" class="text-center">
                    <v-progress-circular :size="60" color="primary" indeterminate />
                    <h4 class="text-center">{{ $t('facturacion.puede_tardar') }}</h4>
                  </div>
                  <div v-else>
                    <v-alert color="indigo lighten-3" :value="true" style="padding: 3px">
                      <v-icon>mdi-information</v-icon>
                      {{ $t('facturacion.info_sobre_facturas') }}
                    </v-alert>
                    <br clear="all" />
                    <h3 class="pb-3">&nbsp;&nbsp;{{ $t('facturacion.pagosrecibidos') }}</h3>
                    <v-data-table
                      :headers="headers"
                      :items="payments"
                      :loading="descargando_loading"
                      :items-per-page-options="[10, 25, 50, { text: $t('datatable.all'), value: -1 }]"
                      class="elevation-1"
                      :items-per-page-text="datatable.rows_per_page"
                      item-key="name"
                    >
                      <template v-slot:item="props">
                        <tr>
                          <td>{{ props.item.invoice }}</td>
                          <td>{{ props.item.concepto }}</td>
                          <td>{{ props.item.nombre_paciente }}</td>
                          <td>{{ props.item.fecha }}</td>
                          <td>{{ props.item.fechaFactura }}</td>
                          <td class="text-right">{{ props.item.importe }}{{ props.item.currency }}</td>
                          <td class="text-right">
                            <v-btn
                              v-if="props.item.email_paciente !== ''"
                              depressed
                              outlined
                              icon
                              fab
                              dark
                              :color="descargando_loading ? 'grey' : 'primary'"
                              small
                              @click="downloadInvoice(props.item)"
                            >
                              <v-icon>mdi-download-box-outline</v-icon>
                            </v-btn>
                          </td>
                        </tr>
                      </template>

                      <template slot="no-data">
                        <v-icon>mdi-alert</v-icon> {{ $t('facturacion.without_payments') }}
                      </template>
                      <template slot="pageText" slot-scope="props">
                        {{ $t('patient.patients') }} {{ props.pageStart }} - {{ props.pageStop }} {{ $t('common.of') }}
                        {{ props.itemsLength }}
                      </template>
                    </v-data-table>
                  </div>
                </v-card-text>
              </v-card>
            </v-flex>
          </v-layout>
          <span slot="widget-content">
            <v-dialog v-model="dialog.show" dialog_delete max-width="500px" scrollable>
              <v-card>
                <v-card-title>
                  <span class="headline">{{ dialog.titulo }}</span>
                </v-card-title>
                <v-divider />
                <v-card-text>
                  <p>{{ dialog.descripcion }}</p>
                </v-card-text>
                <v-divider />
                <v-card-actions>
                  <v-spacer />
                  <v-btn color="grey darken-1" text @click.native="dialog.show = false"
                    ><v-icon>mdi-chevron-left</v-icon> {{ $t('common.cancel') }}</v-btn
                  >
                  <v-btn
                    :color="dialog.color"
                    text
                    @click.native="
                      dialog.show = false;
                      modificarTransaccion();
                    "
                    ><v-icon>{{ dialog.icono }}</v-icon> {{ $t('common.confirm') }}</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-dialog>
          </span>
        </v-card>
      </v-row>
    </v-container>
  </div>
  <div v-else>
    <no-service-granted view="facturacion" />
  </div>
</template>

<script>
import { haveEprescriptionActive } from '@/utils';
import NoServiceGranted from '@/components/common/NoServiceGranted.vue';
import { usuariosUrl, facturacionUrl, transaccionUrl, getHeader } from '../config/config';

export default {
  name: 'Facturacion',
  components: {
    NoServiceGranted,
  },
  data: () => ({
    authUser: JSON.parse(window.localStorage.getItem('auth_ehealth')),
    snackbar: false,
    timeout: 5000,
    mode: '',
    loading: true,
    fini: null,
    finiMenu: false,
    ffinMenu: false,
    ffin: null,
    iniFormatted: null,
    finFormatted: null,
    fmax: new Date(),
    professional: 0,
    dialog: {
      titulo: '',
      descripcion: '',
      id: 0,
      accion: '',
      color: '',
      icono: '',
      show: false,
    },
    headers: [
      { text: 'Num', align: 'left', value: 'invoice', sortable: false },
      { text: vm.$t('facturacion.concepto'), align: 'left', value: 'concepto' },
      { text: vm.$t('common.paciente'), align: 'left', value: 'nombre_paciente' },
      { text: vm.$t('facturacion.datePay'), value: 'fechaPagoBD' },
      { text: vm.$t('facturacion.dateFactura'), value: 'fechaFacturaBD' },
      { text: vm.$t('facturacion.importe'), value: 'importe', align: 'right', sortable: false },
      { text: 'Acciones', value: 'action', align: 'right', sortable: false },
    ],
    professionals: [],
    payments: [],
    datatable: {
      rows_per_page: 'Rows per Page',
    },
    descargando_loading: false,
    disabledButton: true,
    custom_template:
      window.localStorage.getItem('auth_ehealth') &&
      typeof JSON.parse(window.localStorage.getItem('auth_ehealth'))['colorHexadecimal'] !== 'undefined' &&
      JSON.parse(window.localStorage.getItem('auth_ehealth'))['colorHexadecimal'] !== ''
        ? true
        : false,
    showDontHaveService: false,
    isAdmin:
      window.localStorage.getItem('auth_ehealth') &&
      JSON.parse(window.localStorage.getItem('auth_ehealth'))['rol'] === 1,
  }),

  watch: {
    fini() {
      this.iniFormatted = this.formatDate(this.fini);
    },
    ffin() {
      this.finFormatted = this.formatDate(this.ffin);
    },
  },

  mounted() {
    if (!this.hasPermissionBillingModule()) {
      this.$router.push('/');
    } else if (haveEprescriptionActive()) {
      this.showDontHaveService = true;
    } else {
      const date = new Date();
      const primerDia = new Date(date.getFullYear(), date.getMonth() - 1, 1);
      const ultimoDia = new Date(date.getFullYear(), date.getMonth() + 1, 0);
      this.fmax = new Date(date.getFullYear(), date.getMonth() + 1, 0);
      this.fini = primerDia.getFullYear() + '-' + (primerDia.getMonth() + 1) + '-' + primerDia.getDate();
      this.ffin = ultimoDia.getFullYear() + '-' + (ultimoDia.getMonth() + 1) + '-' + ultimoDia.getDate();
      this.datatable.rows_per_page = this.$t('datatable.rows_per_page');
      if (this.isAdmin) {
        this.loadProfessionals();
      } else {
        this.loadInvoices();
      }
    }
  },

  methods: {
    hasPermissionBillingModule() {
      const billingModuleProfessional =
        window.localStorage.getItem('auth_ehealth') &&
        (JSON.parse(window.localStorage.getItem('auth_ehealth'))['rol'] === 2 ||
          JSON.parse(window.localStorage.getItem('auth_ehealth'))['rol'] === 3) &&
        JSON.parse(window.localStorage.getItem('auth_ehealth'))['stripeConfigured'] === 1 &&
        ((JSON.parse(window.localStorage.getItem('auth_ehealth'))['company']['id'] !== null &&
          JSON.parse(window.localStorage.getItem('auth_ehealth'))['is_collective'] === 1) ||
          JSON.parse(window.localStorage.getItem('auth_ehealth'))['company']['id'] === null);
      const billingModuleAdmin =
        window.localStorage.getItem('auth_ehealth') &&
        JSON.parse(window.localStorage.getItem('auth_ehealth'))['rol'] === 1 &&
        JSON.parse(window.localStorage.getItem('auth_ehealth'))['company']['id'] !== null &&
        JSON.parse(window.localStorage.getItem('auth_ehealth'))['is_collective'] === 0;
      return billingModuleProfessional || billingModuleAdmin;
    },

    parseDate(date) {
      if (!date) return null;
      const [day, month, year] = date.split('/');
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
    },

    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split('-');
      return `${day}/${month}/${year}`;
    },

    loadProfessionals() {
      this.loading = true;
      this.$http
        .post(usuariosUrl, { id: this.authUser.id }, { headers: getHeader() })
        .then(response => {
          this.professionals = response.data.usuarios;
        })
        .catch(() => {
          this.$toast.error(this.$t('errors.try_again'));
        })
        .finally(() => {
          this.loading = false;
          this.disabledButton = false;
        });
    },

    loadInvoices() {
      const fechaInicio = new Date(this.fini).getTime();
      const fechaFin = new Date(this.ffin).getTime();

      const diff = fechaFin - fechaInicio;
      const dias = diff / (1000 * 60 * 60 * 24);
      if (dias > 91) {
        this.$toast.warning(this.$t('facturacion.rango_fechas', { num: '90' }));
      } else if (this.isAdmin && this.professional === 0) {
        this.$toast.warning(this.$t('facturacion.select_professional'));
      } else {
        this.disabledButton = true;
        this.loading = true;
        this.payments = [];
        this.datatable.rows_per_page = this.$t('datatable.rows_per_page');
        this.$http
          .post(
            facturacionUrl,
            { id: this.authUser.id, fini: this.fini, ffin: this.ffin, professional: this.professional, accion: 'list' },
            { headers: getHeader() }
          )
          .then(response => {
            if (response.status === 200 && response.data.estado === 1) {
              this.payments = response.data.recibidos;
            }
          })
          .catch(() => {
            this.$toast.error(this.$t('errors.try_again'));
          })
          .finally(() => {
            this.loading = false;
            this.disabledButton = false;
            this.descargando_loading = false;
          });
      }
    },

    downloadInvoice(item) {
      if (!this.descargando_loading) {
        this.descargando_loading = true;
        this.$http
          .post(facturacionUrl, { id: this.authUser.id, item: item, accion: 'download' }, { headers: getHeader() })
          .then(response => {
            if (response.status === 200 && response.data.estado === 1) {
              window.open(response.data.url);
            } else {
              this.$toast.error(this.$t('errors.try_again'));
            }
            this.descargando_loading = false;
          });
      }
    },

    modalTransaccion(id, accion) {
      this.dialog.show = true;
      this.dialog.id = id;
      this.dialog.accion = accion;
      this.dialog.titulo =
        accion === 'aceptar' ? this.$t('facturacion.aceptar_transaccion') : this.$t('facturacion.cancelar_transaccion');
      this.dialog.descripcion =
        accion === 'aceptar'
          ? this.$t('facturacion.aceptar_transaccion_descrip')
          : this.$t('facturacion.cancelar_transaccion_descrip');
      this.dialog.color = accion === 'aceptar' ? 'green' : 'red';
      this.dialog.icono = accion === 'aceptar' ? 'mdi-check' : 'cancel';
    },

    modificarTransaccion() {
      this.$http
        .post(
          transaccionUrl,
          { id: this.authUser.id, transaccion_id: this.dialog.id, accion: this.dialog.accion },
          { headers: getHeader() }
        )
        .then(response => {
          if (response.status === 200 && response.data.estado === 1) {
            this.$toast.success(this.$t('success.save_common'));
            this.loadInvoices();
          } else {
            this.$toast.error(this.$t('errors.try_again'));
          }
        });
    },
  },
};
</script>
